import React from "react";
import {Space, Table, Tag, Row, Col, Button, Modal, Input, Select, message, Pagination,Radio} from 'antd';
import {PlusCircleOutlined, EditOutlined, StopOutlined, ExclamationCircleOutlined,PlusOutlined} from '@ant-design/icons';
import RemoteMinerApi from "../../../api/remoteMinerApi";
import {copyText, formatDate} from '../../../utils/browserUtils';
import PlantServiceApi from "../../../api/PlantServiceApi";
import { ethers } from "ethers";


class Plant extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            columns:true,
            totalCount:0,
            data:false,
            showAdd:false,
            showCreate:false,
            showEdit:false,
            showChange:false,
            confirmLoading:false,
            createText:'OK',
            plantList:[],
            selectPlantList:[],
            ShineMonitorPlantList:[],
            ShineMonitor_1_PlantList:[],
            installPlantInfo:{
                provider:undefined,
                name:undefined,
                pn:undefined,
                sn:undefined,
                devcode:undefined,
                devaddr:undefined,
                ratedPower:undefined,
                location:{
                    lat:undefined,
                    lon:undefined
                }
            },
            plantInfo:{
                provider:undefined,
                stationId:undefined,
                locationType:'GCS',
                name:undefined,
                pn:undefined,
                sn:undefined,
                devcode:undefined,
                devaddr:undefined,
                power:undefined,
                latitude:undefined,
                longitude:undefined,
                owner:undefined,
                region:undefined,
                
            },
            editPlantInfo:{

            },
            providerList:[
                {value: "Solarman", label: "Solarman"},
                {value: "ShineMonitor", label: "ShineMonitor"},
                {value: "ShineMonitor_1", label: "ShineMonitor_1"}
            ],
            locationTypeList:[
                {value: "GCS", label: "GCS"}
            ],
            currencyList:[
                {value: "0x6c28fF02d3A132FE52D022db1f25a33d91caeCA2", label: "gAKRE - 0x6c28fF02d3A132FE52D022db1f25a33d91caeCA2"},
                {value: "0x960c67b8526e6328b30ed2c2faea0355beb62a83", label: "gAKRE(生产) - 0x960c67b8526e6328b30ed2c2faea0355beb62a83"},
                {value: "0x2791bca1f2de4661ed88a30c99a7a9449aa84174", label: "USDC - 0x2791bca1f2de4661ed88a30c99a7a9449aa84174"},
                {value: "0x9C5653339E0B3A99262997FbB541E2562f3361C9", label: "USDC(测试) - 0x9C5653339E0B3A99262997FbB541E2562f3361C9"},
                {value: "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270", label: "WMATIC - 0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270"},
            ],
            createRemoteMinerInfo:{
                crm_type:2,
            },
            plantSearch:{},
            
            createMinerByPlantsInfo:{
                crm_type:2,
                plantIdList:[],
               
            },
            createMinersByPlants:false,
            plantAmountInfo:{minerCount:0,amount:0}
        };
        this.check = this.check.bind(this)
        this.showAddModal = this.showAddModal.bind(this)
        this.handleAddCancel = this.handleAddCancel.bind(this)
        this.onProviderChange = this.onProviderChange.bind(this)
        this.onLocationTypeChange = this.onLocationTypeChange.bind(this)
        this.onStationIdChange = this.onStationIdChange.bind(this)
        this.onPnChange = this.onPnChange.bind(this)
        this.onSnChange = this.onSnChange.bind(this)
        this.onDevaddrChange = this.onDevaddrChange.bind(this)
        this.onDevcodeChange = this.onDevcodeChange.bind(this)
        this.onPowerChange = this.onPowerChange.bind(this)
        this.onLatitudeChange = this.onLatitudeChange.bind(this)
        this.onLongitudeChange = this.onLongitudeChange.bind(this)
        this.onRegionChange = this.onRegionChange.bind(this)
        this.onAmountChange = this.onAmountChange.bind(this)
        this.onExpiredTimeChange = this.onExpiredTimeChange.bind(this)
        this.handleCreateRemoteMiners = this.handleCreateRemoteMiners.bind(this)
        this.handleCreateCancel = this.handleCreateCancel.bind(this)
        this.onCurrencyChange = this.onCurrencyChange.bind(this)
        this.onPriceChange = this.onPriceChange.bind(this)
        this.onCreateAmountChange = this.onCreateAmountChange.bind(this)
        this.onMinerExpiredTimeChange = this.onMinerExpiredTimeChange.bind(this)
        this.handleCreateOk = this.handleCreateOk.bind(this)
        this.onPaginationChange = this.onPaginationChange.bind(this)
        this.handleShowEdit = this.handleShowEdit.bind(this)
        this.handleAddOk = this.handleAddOk.bind(this)
        this.handleEditOk = this.handleEditOk.bind(this)
        this.handleEditCancel = this.handleEditCancel.bind(this)
        this.onEditExpiredTimeChange = this.onEditExpiredTimeChange.bind(this)
        this.queryPlantList = this.queryPlantList.bind(this)
        this.onCrmTypeChange = this.onCrmTypeChange.bind(this)
        this.queryAllPlantList = this.queryAllPlantList.bind(this)
        this.onPlantChange = this.onPlantChange.bind(this)
        this.queryInstalledPlantListFromRM = this.queryInstalledPlantListFromRM.bind(this)
        this.showCreateMiners = this.showCreateMiners.bind(this)
        this.rowSelectionChange = this.rowSelectionChange.bind(this)
        this.handleShowChange = this.handleShowChange.bind(this)
        this.handleChangeCancel = this.handleChangeCancel.bind(this)
        this.handleChangeOk = this.handleChangeOk.bind(this)
        this.onPlantOwnerChange = this.onPlantOwnerChange.bind(this)
    }
    async componentWillMount() {
        await this.check();
    }
    async queryPlantList(){
        let plantSearch = this.state.plantSearch
        let plantList = await RemoteMinerApi.queryPlantList(plantSearch)
        return plantList;
    }

    async queryInstalledPlantListFromRM(){
        let params = {
            offset:1,
            size:100,
            status:'active'
        }
        // let plantSearch = this.state.plantSearch
        let plantList = await RemoteMinerApi.queryPlantList(params)
        return plantList;
    }

    async queryAllPlantList(){
        let params = {};
        let plantList = await PlantServiceApi.queryAllPlantsList(params);
        return plantList;
    }

    async check() {
        const columns = [
            {
                title: 'Plant Address',
                dataIndex: 'plant_id',
                key: 'plant_id',
                width: 200,
                ellipsis:true,
                render:(text) => <a onClick={() => {copyText(text)}}>{text}</a>
            },
            {
                title: 'Status',
                key: 'status',
                width: 80,
                dataIndex: 'status',
                render: (status) => {
                    let color = 'red'
                    let text = '已终止'
                    if(status === 'active'){
                        color = 'green'
                        text = '已激活'
                    }
                    return (
                        <Tag color={color} key={text}>{text}</Tag>
                    );
                }
            },
            {
                title: 'Provider',
                dataIndex: 'provider',
                key: 'provider',
                width: 150,
            },
            {
                title: 'From',
                dataIndex: 'from',
                key: 'from',
                width: 100,
                render: (from) => {
                    let color = 'red'
                    if(from === 'Arkreen'){
                        color = 'green'
                    }
                    return (
                        <Tag color={color} key={from}>{from}</Tag>
                    );
                }
            },
            {
                title: 'Power',
                dataIndex: 'power',
                key: 'power',
                width: 120,
                render: (text) => <span>{Number(text).toFixed(2)+" W"}</span>,
            },
            {
                title: 'Region',
                dataIndex: 'region',
                width: 100,
                key: 'region',
            },
            {
                title: 'Device',
                dataIndex: 'pn',
                width: 200,
                key: 'pn',
            },
            {
                title: 'Miners',
                dataIndex: 'amount',
                width: 150,
                align:'center',
                key: 'amount',
                render:(_,record) => (
                    <span>{(record.minerCount===undefined?0:record.minerCount)+"/"+record.amount}</span>
                ),
            },
            {
                title: 'Expired Time',
                dataIndex: 'expired_time',
                key: 'expired_time',
                width: 180,
                render: (text) => {return <span>{formatDate(text)}</span>},
            },
            {
                title: 'Install Time',
                dataIndex: 'install_time',
                key: 'install_time',
                width: 180,
                render: (text) => {return <span>{formatDate(text)}</span>},
            },
            {
                title: 'Action',
                key: 'action',
                fixed: 'right',
                render: (_, record) => (
                    <Space size="middle">
                        {
                            record.status!=='active'?"":<Button
                                type="primary"
                                size='small'
                                icon={<EditOutlined />}
                                onClick={() => { this.handleShowEdit(record) }}>
                                Edit
                            </Button>
                        }
                        {
                            record.status!=='active'?"":<Button size='small' type="primary" icon={<StopOutlined />}
                                                                onClick={()=>{
                                                                    Modal.confirm({
                                                                        title: 'Are you sure terminate this plant：',
                                                                        icon: <ExclamationCircleOutlined />,
                                                                        content: record.plant_id,
                                                                        okText: 'Yes',
                                                                        cancelText: 'Cancel',
                                                                        onOk:()=>{this.handleTerminate(record)}
                                                                    });}}>终止</Button>
                        }
                        {
                            (record.status !== 'active' || ((Number(record.amount) - Number(record.minerCount?record.minerCount:0))<=0))?"":<Button type="primary" size='small' icon={<PlusOutlined />}
                                onClick={() => {this.handleCreateRemoteMiners(record)}}>
                                创建Miners
                            </Button>
                        }
                        {
                            <Button
                                type="primary"
                                size='small'
                                icon={<EditOutlined />}
                                onClick={() => { this.handleShowChange(record) }}>
                                Change
                            </Button>
                        }
                    </Space>
                ),
            },
        ];
        this.setState({columns:columns})
        // 查询电站列表
        let resp = await this.queryPlantList();
        if(!resp.error){
            let result = resp.result
            if(!result){
                return;
            }
            const data=[]
            if(result.count>0){
                this.setState({totalCount:result.count})
                console.log('result.list = ',result.list);
                result.list.forEach(item =>{
                    let json_accessdata = JSON.parse(item.access_data);
                    const plant = {
                        key:item.plant_id,
                        plant_id:item.plant_id,
                        provider:item.provider,
                        power:item.power,
                        energy:item.energy,
                        region:item.region,
                        amount:item.amount,
                        minerCount:item.minerCount,
                        install_time:item.install_time,
                        expired_time:item.expired_time,
                        status:item.status,
                        pn:item.provider == 'owon'?json_accessdata.params.mac :json_accessdata.params.pn,
                        from:item.owner?'PlantMiner':'Arkreen'
                    }

                    data.push(plant)
                })
            }else {
                this.setState({totalCount:0})
            }
            this.setState({plantList:data})
        }else {
            this.setState({plantList:undefined})
        }
        let installedPlantPn = [];
        let installedRes = await this.queryInstalledPlantListFromRM();
        if(!installedRes.error){
            let result = installedRes.result
            if(result){
                const data=[]
        
                    result.list.forEach(item =>{
                        
                        let json_accessdata = JSON.parse(item.access_data);
                        installedPlantPn.push(json_accessdata.params.pn);
    
                    })
                
            }
            
        }



        let res = await this.queryAllPlantList();
        if(!res.error){
            let result = res.result
            if(!result){
                return;
            }
            console.log(result)
            console.log(installedPlantPn)
            const ShineMonitordata=[];
            const ShineMonitor_1_data=[]
                result.forEach(item =>{

                    // if(!installedPlantPn.includes(item.pn)){
                        const value = {
                            provider:item.provider,
                            name:item.name,
                            pn:item.pn,
                            sn:item.sn,
                            devcode:item.devcode,
                            devaddr:item.devaddr,
                            ratedPower:item.ratedPower,
                            location:item.location,
                            region:item.country
                        }
                        const plant = {
                            label: item.name + '-' + item.pn + '-' + item.sn,
                            value:JSON.stringify(value)
                        }
                        if(item.provider === 'ShineMonitor'){
                            ShineMonitordata.push(plant)
                        }else if(item.provider === 'ShineMonitor_1'){
                            ShineMonitor_1_data.push(plant)
                        }
                        
                    // }

                })
                ShineMonitordata.sort((a, b) => a.label.localeCompare(b.label));
                ShineMonitor_1_data.sort((a, b) => a.label.localeCompare(b.label));
            this.setState({ShineMonitorPlantList:ShineMonitordata})
            this.setState({ShineMonitor_1_PlantList:ShineMonitor_1_data})
            // console.log('111 ' + data.length);
            
        }else{
            this.setState({ShineMonitorPlantList:undefined});
            this.setState({ShineMonitor_1_PlantList:undefined});
        }
    }
    onProviderChange(value){
        console.log(value)
        let plantInfo = this.state.plantInfo
        plantInfo.provider = value

        let PlantList = this.state.selectPlantList;

        this.setState({plantInfo:plantInfo})
        
        if(value === 'ShineMonitor'){
            PlantList = this.state.ShineMonitorPlantList;
            this.setState({selectPlantList:PlantList});
        }else if(value === 'ShineMonitor_1'){
            PlantList = this.state.ShineMonitor_1_PlantList;
            this.setState({selectPlantList:PlantList});
        }
                
    }

    onPlantChange(value){
        let installPlantInfo = this.state.installPlantInfo
        let plant = JSON.parse(value)
        let info = {
            provider:plant.provider,
            name:plant.name,
            pn:plant.pn,
            sn:plant.sn,
            devcode:plant.devcode,
            devaddr:plant.devaddr,
            ratedPower:plant.ratedPower,
            location:{
                lat:0,
                lon:0
            },
            region:plant.region
        }
        if(plant.location.lat){
            info.location.lat = new Number(plant.location.lat);
        }
        if(plant.location.lon){
            info.location.lon = new Number(plant.location.lon);
        }
        this.setState({installPlantInfo:info})
        
        //change plant info
        let plantInfo = this.state.plantInfo
        plantInfo.pn = info.pn;
        plantInfo.sn = info.sn;
        plantInfo.devcode = info.devcode;
        plantInfo.devaddr = info.devaddr;
        plantInfo.power = info.ratedPower;
        plantInfo.latitude = info.location.lat;
        plantInfo.longitude = info.location.lon;
        plantInfo.region = info.region;
        this.setState({plantInfo:plantInfo})
    }
    onLocationTypeChange(value){
        let plantInfo = this.state.plantInfo
        plantInfo.locationType = value
        this.setState({plantInfo:plantInfo})
    }
    onStationIdChange(e){
        const stationId = e.target.value
        let plantInfo = this.state.plantInfo
        plantInfo.stationId = stationId
        this.setState({plantInfo:plantInfo})
    }
    onPnChange(e){
        const pn = e.target.value
        let plantInfo = this.state.plantInfo
        plantInfo.pn = pn
        this.setState({plantInfo:plantInfo})
    }
    onSnChange(e){
        const sn = e.target.value
        let plantInfo = this.state.plantInfo
        plantInfo.sn = sn
        this.setState({plantInfo:plantInfo})
    }
    onDevaddrChange(e){
        const devaddr = e.target.value
        let plantInfo = this.state.plantInfo
        plantInfo.devaddr = devaddr
        this.setState({plantInfo:plantInfo})
    }
    onDevcodeChange(e){
        const devcode = e.target.value
        let plantInfo = this.state.plantInfo
        plantInfo.devcode = devcode
        this.setState({plantInfo:plantInfo})
    }
    onPowerChange(e){
        console.log('23232323')
        console.log(e)
        const power = e.target.value
        let plantInfo = this.state.plantInfo
        plantInfo.power = power
        this.setState({plantInfo:plantInfo})
    }
    onLatitudeChange(e){
        const latitude = e.target.value
        let plantInfo = this.state.plantInfo
        plantInfo.latitude = latitude
        this.setState({plantInfo:plantInfo})
    }
    onLongitudeChange(e){
        const longitude = e.target.value
        let plantInfo = this.state.plantInfo
        plantInfo.longitude = longitude
        this.setState({plantInfo:plantInfo})
    }
    onRegionChange(e){
        const region = e.target.value
        let plantInfo = this.state.plantInfo
        plantInfo.region = region
        this.setState({plantInfo:plantInfo})
    }
    onAmountChange(e){
        const amount = e.target.value
        let plantInfo = this.state.plantInfo
        plantInfo.amount = amount
        this.setState({plantInfo:plantInfo})
    }
    onExpiredTimeChange(e){
        const expiredTime = e.target.value
        let plantInfo = this.state.plantInfo
        plantInfo.expiredTime = expiredTime
        this.setState({plantInfo:plantInfo})
    }
    onPlantOwnerChange(e){
        const owner = e.target.value
        let plantInfo = this.state.plantInfo
        plantInfo.owner = owner
        this.setState({plantInfo:plantInfo})
        
    }
    handleAddOk(){
        const plantInfo = this.state.plantInfo;
        console.log("plantInfo:"+JSON.stringify(plantInfo))
        if(plantInfo){
            if(!plantInfo.provider){
                message.error("请选择电站提供方")
                return;
            }
            if(plantInfo.provider == 'Solarman' && !plantInfo.stationId){
                message.error("请输入目标电站ID")
                return;
            }
            if(plantInfo.provider == 'ShineMonitor' && !plantInfo.pn){
                message.error("请输入目标电站pn号")
                return;
            }
            if(plantInfo.provider == 'ShineMonitor' && !plantInfo.sn){
                message.error("请输入目标电站sn号")
                return;
            }
            if(plantInfo.provider == 'ShineMonitor' && !plantInfo.devaddr){
                message.error("请输入目标电站devaddr")
                return;
            }
            if(plantInfo.provider == 'ShineMonitor' && !plantInfo.devcode){
                message.error("请输入目标电站devcode")
                return;
            }
            if(!plantInfo.locationType){
                message.error("请选择选择位置类型")
                return;
            }
            if(!plantInfo.latitude){
                message.error("请输入维度（latitude）")
                return;
            }
            if(!plantInfo.longitude){
                message.error("请输入经度（longitude）")
                return;
            }
            if(!plantInfo.power){
                message.error("请输入电站总功率")
                return;
            }
            if(!plantInfo.region){
                message.error("请输入Region信息")
                return;
            }
            if(!plantInfo.amount){
                message.error("请输入生成的Miner数量")
                return;
            }
            if(!plantInfo.expiredTime){
                message.error("请输入电站过期时间（天）")
                return;
            }
            if(plantInfo.owner && !ethers.utils.isAddress(plantInfo.owner)){
                message.error("请输入正确的owner地址")
                return;
            }
            //
            this.setState({confirmLoading:true})
            RemoteMinerApi.installPlant(plantInfo).then(resp => {
                if(!resp.error){
                    console.log(resp)
                    message.success("电站安装成功，电站地址："+resp.result)
                    this.check();
                    this.handleAddCancel();
                }else {
                    message.error(resp.error.message)
                }
                this.setState({confirmLoading:false})
            })
            //
        }else {
            message.error("请输入电站安装参数")

        }
    }
    handleAddCancel(){
        let Info = {
            provider:undefined,
            stationId:undefined,
            locationType:'GCS',
            name:undefined,
            pn:undefined,
            sn:undefined,
            devcode:undefined,
            devaddr:undefined,
            power:undefined,
            latitude:undefined,
            longitude:undefined,
            
        };
        this.setState({plantInfo:Info});
        this.setState({showAdd:false})
    }
    handleCreateRemoteMiners(record){
        const createRemoteMinerInfo = {
            plant_id: record.plant_id,
            desAmount: record.amount-(record.minerCount?record.minerCount:0),
            crm_type:2,
            price:9.9,
            expiredTime:365,
            power:Math.round(record.power/record.amount),
        }
        this.setState({createRemoteMinerInfo:{}})
        this.setState({createRemoteMinerInfo:createRemoteMinerInfo})
        this.setState({showCreate:true})
    }
    handleCreateCancel(){
        console.log('handleCreateCancel')
        this.setState({showCreate:false})
        this.setState({createMinersByPlants:false});
    }
    onCurrencyChange(value){
        if(this.state.createMinersByPlants){
            let createMinerByPlantsInfo = this.state.createMinerByPlantsInfo
            createMinerByPlantsInfo.currency = value
            this.setState({createMinerByPlantsInfo:createMinerByPlantsInfo})
        }else{
            let createRemoteMinerInfo = this.state.createRemoteMinerInfo
            createRemoteMinerInfo.currency = value
            this.setState({createRemoteMinerInfo:createRemoteMinerInfo})
        }

    }
    onPriceChange(e){
        if(this.state.createMinersByPlants){
            const price = e.target.value
            let createMinerByPlantsInfo = this.state.createMinerByPlantsInfo
            createMinerByPlantsInfo.price = price
            this.setState({createMinerByPlantsInfo:createMinerByPlantsInfo})
        }else{
            const price = e.target.value
            let createRemoteMinerInfo = this.state.createRemoteMinerInfo
            createRemoteMinerInfo.price = price
            this.setState({createRemoteMinerInfo:createRemoteMinerInfo})
        }

    }
    onCreateAmountChange(e){
        const amount = e.target.value
        if(this.state.createMinersByPlants){
            let createMinerByPlantsInfo = this.state.createMinerByPlantsInfo
            createMinerByPlantsInfo.amount = amount
            this.setState({createMinerByPlantsInfo:createMinerByPlantsInfo})
        }else{
            let createRemoteMinerInfo = this.state.createRemoteMinerInfo
            createRemoteMinerInfo.amount = amount
            this.setState({createRemoteMinerInfo:createRemoteMinerInfo})
        }
        
    }

    onMinerExpiredTimeChange(e){
        const expiredTime = e.target.value
        if(this.state.createMinersByPlants){
            let createMinerByPlantsInfo = this.state.createMinerByPlantsInfo
            createMinerByPlantsInfo.expiredTime = expiredTime
            this.setState({createMinerByPlantsInfo:createMinerByPlantsInfo})
        }else{
            let createRemoteMinerInfo = this.state.createRemoteMinerInfo
            createRemoteMinerInfo.expiredTime = expiredTime
            this.setState({createRemoteMinerInfo:createRemoteMinerInfo})
        }

    }
    handleCreateOk(){
        console.log('handleCreateOk, createMinersByPlants = ',this.state.createMinersByPlants)
        if(this.state.createMinersByPlants){
            let createMinerByPlantsInfo = this.state.createMinerByPlantsInfo
            //
            if(!createMinerByPlantsInfo.plantIdList || createMinerByPlantsInfo.plantIdList.length === 0){
                message.error("电站信息异常，请刷新页面重试")
                return;
            }
            if(!createMinerByPlantsInfo.price){
                message.error("请输入Remote Miner单价")
                return;
            }
            if(!createMinerByPlantsInfo.currency){
                message.error("请选择用于支付的货币")
                return;
            }
            if(!createMinerByPlantsInfo.expiredTime){
                message.error("请输入过期时间（天）")
                return;
            }
            if(!createMinerByPlantsInfo.amount){
                message.error("请输入要创建的Remote Miner数量")
                return;
            }
            if(createMinerByPlantsInfo.amount>createMinerByPlantsInfo.desAmount){
                message.error("创建的Remote Miner数量不得多余"+createMinerByPlantsInfo.desAmount+"个")
                return;
            }
            //
            this.setState({confirmLoading:true})
            this.setState({createText:"Creating..."})
            RemoteMinerApi.createRemoteMinersNew(createMinerByPlantsInfo).then(resp => {
                if(!resp.error){
                    message.success("创建RemoteMiner成功")
                    this.check();
                    this.handleCreateCancel();
                }else {
                    message.error(resp.error.message)
                }
                this.setState({createText:"OK"})
                this.setState({confirmLoading:false})
            })
        }else{
            let createRemoteMinerInfo = this.state.createRemoteMinerInfo
            //
            if(!createRemoteMinerInfo.plant_id){
                message.error("电站信息异常，请刷新页面重试")
                return;
            }
            if(!createRemoteMinerInfo.price){
                message.error("请输入Remote Miner单价")
                return;
            }
            if(!createRemoteMinerInfo.currency){
                message.error("请选择用于支付的货币")
                return;
            }
            if(!createRemoteMinerInfo.expiredTime){
                message.error("请输入过期时间（天）")
                return;
            }
            if(!createRemoteMinerInfo.amount){
                message.error("请输入要创建的Remote Miner数量")
                return;
            }
            if(createRemoteMinerInfo.amount>createRemoteMinerInfo.desAmount){
                message.error("创建的Remote Miner数量不得多余"+createRemoteMinerInfo.desAmount+"个")
                return;
            }
            if(createRemoteMinerInfo.owner && !ethers.utils.isAddress(createRemoteMinerInfo.owner)){
                message.error("owner's address error of this plant")
                return;
            }
            //
            this.setState({confirmLoading:true})
            this.setState({createText:"Creating..."})
            RemoteMinerApi.createRemoteMiners(createRemoteMinerInfo).then(resp => {
                if(!resp.error){
                    message.success("创建RemoteMiner成功")
                    this.check();
                    this.handleCreateCancel();
                }else {
                    message.error(resp.error.message)
                }
                this.setState({createText:"OK"})
                this.setState({confirmLoading:false})
            })
        }
        
    }
    async handleTerminate(record) {
        console.log(record)
        RemoteMinerApi.terminatePlant(record.plant_id).then(resp =>{
            if(!resp.error){
                message.success("电站终止成功")
                this.check();
            }else {
                message.error(resp.error.message)
            }
        })
    }
    onPaginationChange(page, pageSize){
        let plantSearch = this.state.plantSearch
        plantSearch.offset = page
        plantSearch.size = pageSize
        this.setState({plantSearch:plantSearch})
        this.check();
    }
    showAddModal(){
        this.setState({showAdd:true})
    }
    onCrmTypeChange(e){
        console.log(e.target.value)
        if(this.state.createMinersByPlants){
            let minerInfo =  this.state.createMinerByPlantsInfo;
            minerInfo.crm_type = e.target.value;
            this.setState({createMinerByPlantsInfo:minerInfo});
        }else{
            let createRemoteMinerInfo = this.state.createRemoteMinerInfo
            createRemoteMinerInfo.crm_type = e.target.value
            this.setState({createRemoteMinerInfo:createRemoteMinerInfo})
        }
  
    }


    handleEditOk(){
        const editPlantInfo = this.state.editPlantInfo;
        console.log("editPlantInfo:"+JSON.stringify(editPlantInfo))
        if(!editPlantInfo.plant_id || editPlantInfo.plant_id.length !== 42){
            message.error("电站信息异常，请刷新页面后重试！")
            return;
        }
        if(!editPlantInfo.expired_time){
            message.error("过期天数不能为空！")
            return;
        }
        //
        RemoteMinerApi.updatePlant(editPlantInfo).then(resp => {
            console.log(resp)
            if(!resp.error){
                message.success("电站信息修改成功！")
                this.setState({showEdit:false})
                this.check()
            }else {
                message.error(resp.error.message)
            }
        })
    }
    handleChangeOk(){
        this.setState({confirmLoading:true})
        const editPlantInfo = this.state.editPlantInfo;
        console.log("editPlantInfo:"+JSON.stringify(editPlantInfo))
        if(!editPlantInfo.plant_id || editPlantInfo.plant_id.length !== 42){
            message.error("电站信息异常，请刷新页面后重试！")
            this.setState({confirmLoading:false})
            return;
        }
        //
        let data = {
            plantId:editPlantInfo.plant_id
        }
        RemoteMinerApi.changePlant(data).then(resp => {
            console.log(resp)
            if(!resp.error){
                message.success("电站信息修改成功！")
                this.setState({showChange:false})
                this.check()
            }else {
                message.error(resp.error.message)
            }
            this.setState({confirmLoading:false})
        })
    }
    handleEditCancel(){
        this.setState({showEdit:false})
    }
    handleChangeCancel(){
        this.setState({showChange:false})
    }
    onEditExpiredTimeChange(e){
        let editPlantInfo = this.state.editPlantInfo
        editPlantInfo.expired_time = e.target.value
        this.setState({editPlantInfo:editPlantInfo})
    }
    handleShowEdit(record){
        let editPlantInfo = this.state.editPlantInfo;
        editPlantInfo.plant_id = record.plant_id
        const t = Math.round(Date.now() / 1000)
        const time = record.expired_time - t;
        editPlantInfo.expired_time = Math.round(time/86400)
        this.setState({editPlantInfo:editPlantInfo})
        this.setState({showEdit:true})
    }
    handleShowChange(record){
        let editPlantInfo = this.state.editPlantInfo;
        editPlantInfo.plant_id = record.plant_id
        this.setState({editPlantInfo:editPlantInfo})
        this.setState({showChange:true})
    }

    rowSelectionChange(selectedRowKeys,selectedRows){
        console.log(selectedRowKeys);
        let minerInfo = this.state.createMinerByPlantsInfo;
        // minerInfo.selectedRowKeys = selectedRows;
        minerInfo.plantIdList = selectedRowKeys;
        
        let minerCount = 0;
        let amount = 0;
        selectedRows.forEach((item)=>{
            minerCount+= item.minerCount;
            amount += item.amount;
        })
        this.setState({plantAmountInfo:{minerCount:minerCount,amount:amount}});
        minerInfo.desAmount = amount - minerCount;
        this.setState({createMinerByPlantsInfo:minerInfo});
    }
    showCreateMiners(){
        this.setState({createMinersByPlants:true});
        this.setState({showCreate:true})
    }


    render() {
        return (
            <div style={{width:'100%',height:'100%',overflow:"hidden"}}>
                <Row>
                    <Col span={12}>
                            <span style={{fontWeight:'bold',fontSize:18}}>
                                Plant Manage
                            </span>
                    </Col>
                    <Col span={12}>
                        <div style={{textAlign:'right'}}>
                            <Button style={{marginRight:'10px'}} size='small' onClick={this.showAddModal} type="primary" icon={<PlusCircleOutlined />}>安装电站</Button>
                            <Button size='small' onClick={this.showCreateMiners} type="primary" icon={<PlusCircleOutlined />}>交叉创建miner</Button>
                        </div>
                    </Col>
                </Row>
                <div style={{marginTop:10,width:'100%',height:'100%',overflow:'auto'}}>
                    {
                        this.state.totalCount>10?<div style={{textAlign:'right',paddingTop:15,paddingBottom:15}}>
                            <Pagination onChange={this.onPaginationChange} size="small" total={this.state.totalCount} showSizeChanger showQuickJumper />
                        </div>:''
                    }
                    <Table rowSelection={{getCheckboxProps:(record, index)=>{if(record.minerCount >= record.amount){return { disabled: true };}},onChange:(selectedRowKeys,selectedRows)=>{this.rowSelectionChange(selectedRowKeys,selectedRows)} }} scroll={{x: 1775}} size='small' pagination={false} columns={this.state.columns} dataSource={this.state.plantList} />
                </div>
                <Modal width='800px' title="Install Plant" destroyOnClose={true} open={this.state.showAdd} onOk={this.handleAddOk} confirmLoading={this.state.confirmLoading} onCancel={this.handleAddCancel}>
                    <Row>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>Provider:</span>
                        </Col>
                        <Col span={20}>
                            <Select style={{width:'100%'}} placeholder="Select a provider" onChange={this.onProviderChange} allowClear options={this.state.providerList} />
                        </Col>
                        <Col span={24} style={{height:10}}>

                        </Col>
                        {
                            this.state.plantInfo.provider === 'Solarman'?<>
                                <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                                    <span style={{lineHeight:'32px'}}>StationId:</span>
                                </Col>
                                <Col span={20}>
                                    <Input type='number' placeholder="station id" onChange={this.onStationIdChange} />
                                </Col>
                                <Col span={24} style={{height:10}}></Col>
                            </>:<></>
                        }
                        {
                            (this.state.plantInfo.provider === 'ShineMonitor' || this.state.plantInfo.provider === 'ShineMonitor_1')?<>
                                
                                <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                                    <span style={{lineHeight:'32px'}}>Plant:</span>
                                </Col>
                                <Col span={20}>
                                <Select style={{width:'100%'}} placeholder="Select a plant" onChange={this.onPlantChange} allowClear options={this.state.selectPlantList}  />
                                </Col>
                                <Col span={24} style={{height:10}}></Col>
                                
                                <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                                    <span style={{lineHeight:'32px'}}>PN:</span>
                                </Col>
                                <Col span={20}>
                                    <Input placeholder="pn" onChange={this.onPnChange} value={this.state.plantInfo.pn} />
                                </Col>
                                <Col span={24} style={{height:10}}></Col>
                                <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                                    <span style={{lineHeight:'32px'}}>SN:</span>
                                </Col>
                                <Col span={20}>
                                    <Input placeholder="sn" onChange={this.onSnChange} value={this.state.plantInfo.sn}/>
                                </Col>
                                <Col span={24} style={{height:10}}></Col>
                                <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                                    <span style={{lineHeight:'32px'}}>Devaddr:</span>
                                </Col>
                                <Col span={20}>
                                    <Input placeholder="devaddr" onChange={this.onDevaddrChange} value={this.state.plantInfo.devaddr}/>
                                </Col>
                                <Col span={24} style={{height:10}}></Col>
                                <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                                    <span style={{lineHeight:'32px'}}>Devcode:</span>
                                </Col>
                                <Col span={20}>
                                    <Input placeholder="devcode" onChange={this.onDevcodeChange} value={this.state.plantInfo.devcode}/>
                                </Col>
                                <Col span={24} style={{height:10}}></Col>
                            </>:<></>
                        }
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>Location:</span>
                        </Col>
                        <Col span={20}>
                            <Row>
                                <Col span={8}>
                                    <Select value={this.state.plantInfo.locationType} style={{width:'100%'}} placeholder="Location type" onChange={this.onLocationTypeChange} allowClear options={this.state.locationTypeList} />
                                </Col>
                                <Col span={8} style={{paddingLeft:5}}>
                                    <Input type='number' placeholder="latitude" onChange={this.onLatitudeChange} value={this.state.plantInfo.latitude} />
                                </Col>
                                <Col span={8} style={{paddingLeft:5}}>
                                    <Input type='number' placeholder="longitude" onChange={this.onLongitudeChange} value={this.state.plantInfo.longitude}/>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24} style={{height:10}}>

                        </Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>Power:</span>
                        </Col>
                        <Col span={20}>
                            <Input placeholder="plant total power" onChange={this.onPowerChange} suffix="W"  value={this.state.plantInfo.power}/>
                        </Col>
                        <Col span={24} style={{height:10}}>

                        </Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>Region:</span>
                        </Col>
                        <Col span={20}>
                            <Input placeholder="region" onChange={this.onRegionChange} value={this.state.plantInfo.region}/>
                        </Col>
                        <Col span={24} style={{height:10}}>

                        </Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>Miner Amount:</span>
                        </Col>
                        <Col span={20}>
                            <Input type='number' placeholder="miner amount of this plant" onChange={this.onAmountChange} />
                        </Col>
                        <Col span={24} style={{height:10}}>

                        </Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>Expired Time:</span>
                        </Col>
                        <Col span={20}>
                            <Input type='number' placeholder="expired time of this plant" onChange={this.onExpiredTimeChange} suffix="days"/>
                        </Col>
                        <Col span={24} style={{height:10}}>

                        </Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>Owner:</span>
                        </Col>
                        <Col span={20}>
                            <Input  placeholder="owner's address of this plant" onChange={this.onPlantOwnerChange} />
                        </Col>
                    </Row>
                </Modal>
                <Modal okText={this.state.createText} width='800px' title="Create Remote Miners" destroyOnClose={true} open={this.state.showCreate} onOk={this.handleCreateOk} confirmLoading={this.state.confirmLoading} onCancel={this.handleCreateCancel}>
                    <Row>
                        <Col span={24} style={{height:10}}>

                        </Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>目标电站:</span>
                        </Col>
                        {
                            this.state.createMinersByPlants? 
                            <Col span={20}>
                            {
                            this.state.createMinerByPlantsInfo.plantIdList.map((item)=> <div key={Math.random()}> <span style={{lineHeight:'32px'}}>{item}</span></div>)
                            }
                            </Col>
                            :
                             <Col span={20}>
                            <span style={{lineHeight:'32px'}}>{this.state.createRemoteMinerInfo.plant_id}</span>
                            </Col> 
                            
                        }
                        
                        {/* <Col span={20}>
                            <span style={{lineHeight:'32px'}}>{this.state.createRemoteMinerInfo.plant_id}</span>
                        </Col> */}
                        <Col span={24} style={{height:10}}>

                        </Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>创建类型:</span>
                        </Col>
                        <Col span={20}>
                            <span style={{lineHeight:'32px'}}>
                                <Radio.Group onChange={this.onCrmTypeChange} value={this.state.createRemoteMinerInfo.crm_type}>
                                    <Radio value={1}>单个创建</Radio>
                                    <Radio value={2}>批量-固定100W</Radio>
                                    <Radio value={3}>批量-其他功率</Radio>
                                </Radio.Group>
                            </span>
                        </Col>
                        <Col span={24} style={{height:10}}>

                        </Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>Power:</span>
                        </Col>
                        <Col span={20}>
                            <span style={{lineHeight:'32px',color:'red'}}> {this.state.createRemoteMinerInfo.power}  W</span>
                        </Col>
                        <Col span={24} style={{height:10}}>

                        </Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>Price:</span>
                        </Col>
                        <Col span={20}>
                            <Input readOnly={this.state.confirmLoading} type='number' placeholder="price" onChange={this.onPriceChange} value={this.state.createRemoteMinerInfo.price}/>
                        </Col>
                        <Col span={24} style={{height:10}}>

                        </Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>Currency:</span>
                        </Col>
                        <Col span={20}>
                            <Select style={{width:'100%'}} placeholder="Select a currency" onChange={this.onCurrencyChange} allowClear options={this.state.currencyList} />
                        </Col>
                        <Col span={24} style={{height:10}}>

                        </Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>Expired Time:</span>
                        </Col>
                        <Col span={20}>
                            <Input readOnly={this.state.confirmLoading} type='number' placeholder="expired time of this miner" onChange={this.onMinerExpiredTimeChange} suffix="days" value={this.state.createRemoteMinerInfo.expiredTime}/>
                        </Col>
                        <Col span={24} style={{height:10}}>

                        </Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>Create Amount:</span>
                        </Col>
                        <Col span={20}>
                            <Input readOnly={this.state.confirmLoading} type='number' placeholder="Create miner amount of this plant" onChange={this.onCreateAmountChange} />
                        </Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>

                        </Col>
                        
                        <Col span={20}>
                            {
                                this.state.createMinersByPlants?
                                    <span style={{color:'orange'}}>
                                
                                        剩余可创建数量：{this.state.plantAmountInfo.amount-this.state.plantAmountInfo.minerCount}
                                    </span>
                                :
                                    <span style={{color:'orange'}}>
                                
                                剩余可创建数量：{this.state.createRemoteMinerInfo.desAmount}
                                </span>
                            }
                            {/* <span style={{color:'orange'}}>
                                
                                剩余可创建数量：{this.state.createRemoteMinerInfo.desAmount}
                            </span> */}
                        </Col>
                        <Col span={24} style={{height:10}}>

                        </Col>
                        {
                            ((this.state.confirmLoading==false) || (this.state.createRemoteMinerInfo.amount<10))?"":<Col span={24}><span style={{fontSize:18,paddingLeft:30}}>创建的Remote Miner 数量较多，请耐心等待...</span></Col>
                        }
                    </Row>
                </Modal>
                <Modal width='800px' title="Edit Plant Info" destroyOnClose={true} open={this.state.showEdit} onOk={this.handleEditOk} confirmLoading={this.state.confirmLoading} onCancel={this.handleEditCancel}>
                    <Row>
                        <Col span={24} style={{height:10}}>

                        </Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>目标电站:</span>
                        </Col> 
                            <Col span={20}>
                                <span style={{lineHeight:'32px'}}>{this.state.editPlantInfo.plant_id}</span>
                            </Col>
                        <Col span={24} style={{height:10}}>

                        </Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>剩余过期天数:</span>
                        </Col>
                        <Col span={20}>
                            <Input value={this.state.editPlantInfo.expired_time} type='number' placeholder="expired time of this plant" onChange={this.onEditExpiredTimeChange} suffix="days"/>
                        </Col>
                    </Row>
                </Modal>

                <Modal width='800px' title="Change Plant Info" destroyOnClose={true} open={this.state.showChange} onOk={this.handleChangeOk} confirmLoading={this.state.confirmLoading} onCancel={this.handleChangeCancel}>
                    <Row>
                        <Col span={24} style={{height:10}}>

                        </Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>目标电站:</span>
                        </Col> 
                            <Col span={20}>
                                <span style={{lineHeight:'32px'}}>{this.state.editPlantInfo.plant_id}</span>
                            </Col>
                        <Col span={24} style={{height:10}}>

                        </Col>
                    </Row>
                </Modal>
            </div>
        )
    }
}

export default Plant;
