import HttpUtils from '../utils/HttpUtils'
import {getFilters} from "../utils/browserUtils";
const baseUrl = process.env.REACT_APP_BASE_API

async function queryAllPlantsList(params){
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_plant_queryAllPlantList", params,token)
}

async function queryPlantDataByDeviceId(params){
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_plant_querydataByDevicdId", params,token)
}




const PlantServiceApi ={
    queryAllPlantsList:queryAllPlantsList,
    queryPlantDataByDeviceId:queryPlantDataByDeviceId
}

export default PlantServiceApi;
