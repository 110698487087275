import {getFilters} from "../utils/browserUtils";
import HttpUtils from '../utils/HttpUtils'
const baseUrl = process.env.REACT_APP_BASE_API


async function queryPlantList(params){
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_plant_list", params,token)
}

async function installPlant(params){
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_plant_install", params,token)
}

async function createRemoteMiners(params){
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_plant_crm", params,token)
}

async function createRemoteMinersNew(params){
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_plant_crm_new", params,token)
}

async function terminatePlant(plant_id){
    const params = {
        plant_id:plant_id
    }
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_plant_terminate", params,token)
}

async function queryMinerList(params){
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_rm_list", params,token)
}

async function updatePlant(params){
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_plant_update", params,token)
}

async function updateMiner(params){
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_rm_update", params,token)
}

async function changePlant(params){
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_rms_changePlant", params,token)
}

const RemoteMinerApi ={
    queryPlantList:queryPlantList,
    installPlant:installPlant,
    createRemoteMiners:createRemoteMiners,
    terminatePlant:terminatePlant,
    queryMinerList:queryMinerList,
    updatePlant:updatePlant,
    updateMiner:updateMiner,
    createRemoteMinersNew:createRemoteMinersNew,
    changePlant:changePlant,
}

export default RemoteMinerApi;
