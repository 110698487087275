import HttpUtils from '../utils/HttpUtils'
import {getFilters} from "../utils/browserUtils";
const baseUrl = process.env.REACT_APP_BASE_API

async function queryPreorderList(params){
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_market_queryPreorder", params,token)
}

async function inserPreorderMsg(params){
    const token = getFilters("token");

    const res =  await HttpUtils.sendRequest(baseUrl, "aop_market_insertPreorder", params,token);
    console.log(res)

    return res;
}


async function deletePreorderMsg(params){
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_market_deletePreorder", params,token)
}

async function resendEmailForPreorder(params){
    const token = getFilters("token");
    return await HttpUtils.sendRequest(baseUrl, "aop_market_resendEmail", params,token)
}




const MarketServiceApi ={
    queryPreorderList:queryPreorderList,
    inserPreorderMsg:inserPreorderMsg,
    deletePreorderMsg:deletePreorderMsg,
    resendEmailForPreorder:resendEmailForPreorder,
}

export default MarketServiceApi;
